<template>
  <div class="custom-menu" @click="selectItem(menu, menuParent)">
    <v-tooltip top v-if="checkLicense(menu)">
      <template v-slot:activator="{ on, attrs }">
        <div class="license-icon" v-bind="attrs" v-on="on">
          <v-icon style="margin: 5px;" size="32" color="black">mdi-crown-outline</v-icon>
        </div>
      </template>
      <span>Need License</span>
    </v-tooltip>

    <v-img v-if="menu.image_path" class="menu-img" :src="`${config.backend_pub}${menu.image_path}`" />
    <div v-else style="width: 100%;height:200px; display: flex;justify-content:center;align-items:center">
      <v-icon size="128">{{ menu.icon }}</v-icon>
    </div>
    <span class="ml-2 mr-2 mt-2 menu-title">{{ menu.name }}</span>
    <div class="description">{{ menu.description }}</div>
  </div>
</template>
<script>
import config from '@/config'

export default {
  name: "menu-item",
  props: {
    menu: { type: Object, default: () => ({}) },
    menuParent: { type: Object, default: undefined },
    projects: { type: Array, default: () => [] },
    checkLicense: { type: Function, default: () => false },
  },
  data() {
    return {
      config: config
    }
  },
  methods: {
    selectItem(item, parent) {
      this.$emit('selectItem', item, parent)
    },
  }
}
</script>
<style lang="scss" scoped>
$customBorderRadius: 12px;

.custom-menu {
  min-width: 220px;
  border-radius: $customBorderRadius;
  width: 310px;
  flex-direction: column;
  background-size: cover;
  position: relative;
  background-color:#161f49;
  border: 1px solid #222;
  cursor: pointer;
  .menu-img {
    width: 100%;
    height: 200px;
    object-fit: contain;
    border-top-left-radius: $customBorderRadius;
    border-top-right-radius: $customBorderRadius;
    margin-bottom: 10px;
  }
  &:hover {
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5) !important;
  }
}
.license-icon {
  background-color: rgba(39, 44, 78, 0.5) !important;
  position: absolute;
  top: 0px;
  right: 0px;
  border-top-right-radius: 12px;
  border-bottom-left-radius: 12px;
  z-index: 1;
}
.license-icon :hover {
  filter: brightness(1.5);
}
::v-deep .license-icon .theme--dark.v-icon {
  color: rgb(255, 219, 61) !important;
}
.description {
  font-size: 16px;
  font-weight: normal;
  color: #e3e5eb;
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  text-align: left;
}
.menu-title {
  font-size: 20px;
  font-weight: bold;
  color: white;
  text-align: center;
}
</style>
