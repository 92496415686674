<template>
  <div class="fill-height overflow-y-auto" >
    <v-card  v-if="groupItemMenu.length > 0" class="vertical-nav-menu-items mb-1 overflow-y-auto custom-card-bg-1 ">
      <v-card-text class="grid-container">
          <MenuItem
            v-for="(item, index) in groupItemMenu" :key="index"
            :menu="item"
            :projects="projects"
            :check-license="checkLicense"
            @selectItem="selectItem"
          />
      </v-card-text>
    </v-card>
    <div
      v-for="(item, index) in groupItemOther"
      :key="index"
    >
      <v-card class="vertical-nav-menu-items mb-1 overflow-y-auto custom-card-bg-1">
        <span :ref="`${item.code}`"></span>
        <v-card-title>{{ item.name }}</v-card-title>
        <v-card-text class="grid-container">
          <MenuItem
            v-for="(childMenu, index) in item.children"
            :key="index"
            :menu="childMenu"
            :menuParent="item"
            :projects="projects"
            :check-license="checkLicense"
            @selectItem="selectItem"
          />
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>
<script>
import { mapState } from '@/store/ults'
import config from '@/config'
import MenuItem from './MenuItem.vue'

export default {
  name: 'GroupView',
  components: {
    MenuItem,
  },
  data() {
    return {
      config: config,
      groupItem: [],
      currentGroup: null,
    }
  },
  props: {},
  computed: {
    ...mapState('menu', ['menu']),
    ...mapState('auth', ['currentUser']),
    ...mapState('project', ['projects']),
    groupItemMenu() {
      return this.groupItem.filter(item => item.type === 'menu' && this.checkDisplay(item))
    },
    groupItemOther() {
      return this.groupItem
        .filter(item => (item.type === 'group' || item.type === 'domain') && this.checkDisplay(item))
        .map(item => {
          const children =  item.children.filter(childItem => this.checkDisplay(childItem))
          return {
            ...item,
            children
          }
        })
    },
    group: {
      get() {
        if (!this.$route.query.group) this.$router.push({ query: { group: 'home' } })
        return this.$route.query.group.split('/')[0] || ''
      },
      set(newValue) {
        this.$router.push({ query: { ...this.$route.query, group: newValue } })
      },
    },
  },
  watch: {
    '$route.query.group'(){
      this.$nextTick(() => {
        this.scrollToGroup()
      })
    },
    menu(val) {
      this.groupItem = this.findGroupItem(this.group)
    },
    group(val) {
      this.groupItem = this.findGroupItem(val)

    },
  },
  mounted() {
    this.groupItem = this.findGroupItem(this.group)
    this.$nextTick(() => {
      this.scrollToGroup()
    })
    this.$store.commit('project/SET_CURRENT_PROJECT_ID', this.$route.params.id)
  },
  methods: {
    scrollToGroup(){
      const cardRef = this.$refs[`${this.$route.query.group.split('/')[1] || ''}`]
      if (!cardRef) return
      cardRef[0].scrollIntoView({ behavior: 'smooth' })
    },
    findGroupItem(group) {
      if (group === 'home') return this.menu
      if (!group) return this.menu
      let menu = this.menu.find(menu => menu.code === group)
      if (menu && menu.children) {
        menu = menu.children
      } else {
        menu = this.menu
      }
      return menu
    },

    checkLicense(item) {
      if (item.type == 'group') {
        for (let i = 0; i < item.children.length; i++) {
          if (this.checkLicense(item.children[i]) == false) {
            return false
          }
        }
        return true
      }
      return this.currentUser.menus.some(menu => menu.code === item.code && !menu.have_license)
    },
    checkDisplay(item) {
      if (item.code === 'home') return true
      let currentProject = this.projects.find(project => project.uuid === this.$route.params.id)
      if (item.type == 'domain' || item.type == 'group') {
        let isChildrenViewable = false
        for (let i = 0; i < item.children.length; i++) {
          if (this.checkDisplay(item.children[i])) {
            isChildrenViewable = true
            break
          }
        }
        return isChildrenViewable
      }
      if (item.type === 'menu' && currentProject) {
        return currentProject.menus.some(menu => menu.code === item.code && menu.viewable)
      }
    },

    selectItem(item, parent) {
      if (this.checkLicense(item)) {
        this.$store.commit('message/SHOW_WARNING', 'Unlicensed category!')
        return
      }
      if (item.type == 'group') {
        this.$router.push('/projects/' + this.$route.params.id + '/group-view/?group=' + parent.code + '/' + item.code)
        return
      }
      let domain = this.$route.query.group.split('/')[0] || ''
      let group = parent ? parent.code + '/' : ''
      let urlGroup = domain + '/' + group + item.code
      this.$router.push({ name: item.code, query: { group: urlGroup } })
    },
  },
}
</script>

<style scoped lang="scss">
.grid-container {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fill, minmax(300px, auto));
  gap: 20px;
  justify-content: start;
}
</style>
